import clone from "clone";
import { DateTime } from "luxon";
import { EmployeeGroup, EmploymentType, Subsidiary } from "..";

export class EmployeeCompensationInformation
{
	/**
	 * Creates a new instance of the type.
	 * @param data The response data from which the instance should be created.
	 */
	public constructor(data: any)
	{
		this.employmentType = this.employmentTypeFromJSON(data.employmentType);
		this.employmentTypeLocked = data.employmentTypeLocked ?? false;
		this.monthlySalary = data.monthlySalary;
		this.monthlySalaryLocked = data.monthlySalaryLocked ?? false;
		this.companyContributionPercentage = data.companyContribution;
		this.mandatoryPolicyStartDate = data.mandatoryPolicyStartDate == null ? undefined : DateTime.fromISO(data.mandatoryPolicyStartDate, { zone: "utc" });
		this.mandatoryPolicyStartDateLocked = data.mandatoryPolicyStartDateLocked ?? false;
		this.possibleEmployeeGroups = data.employeeGroups?.map(employeeGroup => new EmployeeGroup(employeeGroup));
		this.employeeGroup = this.possibleEmployeeGroups?.find(employeeGroup => data.employeeGroupId === employeeGroup.id);
		this.employeeGroupLocked = data.employeeGroupLocked ?? false;

		this.possibleSubsidiaries = data
			.subsidiaries
			?.map(subsidiary => new Subsidiary(subsidiary))
			?.sort((a, b) =>
			{
				if(a.name > b.name)
				{
					return 1;
				}
				else
				{
					return -1;
				}
			}) ?? Subsidiary[0];

		this.subsidiary = this.possibleSubsidiaries?.find(subsidiary => data.subsidiaryId === subsidiary.id);
		this.subsidiaryLocked = data.subsidiaryLocked ?? false;
	}

	public employmentType: EmploymentType | undefined;
	public readonly employmentTypeLocked: boolean;
	public monthlySalary: number | undefined;
	public readonly monthlySalaryLocked: boolean;
	public companyContributionPercentage: number | undefined;
	public mandatoryPolicyStartDate: DateTime | undefined;
	public readonly mandatoryPolicyStartDateLocked: boolean;
	public readonly possibleEmployeeGroups: EmployeeGroup[] | undefined;
	public readonly employeeGroup: EmployeeGroup | undefined;
	public readonly employeeGroupLocked: boolean;
	public readonly possibleSubsidiaries: Subsidiary[];
	public readonly subsidiary: Subsidiary | undefined;
	public readonly subsidiaryLocked: boolean;

	/**
	 * Translates the frontend value to backend ditto
	 */
	public employmentTypeFromJSON(value: number): EmploymentType | undefined
	{
		if (value === 2)
		{
			return new EmploymentType("owner");
		}
		else if (value === 1)
		{
			return new EmploymentType("employee");
		}

		return undefined;
	}

	/**
	 * Gets a clone of this instance, suitable for editing.
	 */
	public clone(): any
	{
		return clone(this);
	}

	/**
	 * Gets the data representing this instance.
	 */
	public toJSON(): any
	{
		return {
			monthlySalary: this.monthlySalary,
			companyContribution: this.employmentType?.slug == "employee" ? this.companyContributionPercentage : 0,
			mandatoryPolicyStartDate: this.mandatoryPolicyStartDate?.toISODate(),
			employmentType: this.employmentType?.value,
			employeeGroupId: this.employeeGroup?.id,
			subsidiaryId: this.subsidiary?.id
		};
	}
}
